@import "mixins"
.sportGames
    background: var(--home_tournamentItemBg)
    border-radius: 4px
    padding: var(--home_tournamentItemPadding)
    margin-bottom: var(--home_tournamentItemMargin)
    color: rgb(var(--secondaryColor))
    min-height: calc(176px + var(--sportsNav_height) + 48px)
    position: relative
    :global(.slick-track)
        margin: 0

    > :global(.loader)
        position: static
        min-height: calc(166px + var(--sportsNav_height))

.header
    min-height: 48px
    @include flex
    justify-content: space-between
    height: 48px
    padding: 0 3px

    > span
        @include flex
        @include bold
        background: var(--home_headerSportBg)
        border-radius: 30px
        padding: 6px 12px
        margin-right: 20px
        min-width: 110px
        height: 32px
        text-transform: uppercase
        white-space: nowrap
        color: rgb(var(--thirdaryColor))
        :global(.svg-icon)
            margin-right: 6px
            svg
                path
                    fill: rgb(var(--thirdaryColor)) !important

    > a
        color: rgb(var(--primaryColor))
        &:hover
            color: rgb(var(--secondaryColor))

    + div
        border-radius: var(--base_borderRadius)
        margin: 0
