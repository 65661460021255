@import "mixins"
.default
  background: var(--casino_leftNavBg)
  border-radius: 10px
  padding: 14px
  font-size: 12px
  margin-bottom: 32px
  .header
    @include flex
    justify-content: space-between
    margin-bottom: 14px

    .showAll
      color: rgb(var(--thirdaryColor))

.carousel
  width: 100%
  font-size: 12px
  margin-bottom: 32px
  position: relative
  .header
    @include flex
    justify-content: space-between
    margin-bottom: 14px
    padding-right: 70px
    .showAll
      color: rgb(var(--secondaryColor))
      &:hover
        color: rgb(var(--thirdaryColor))

.tagName
  @include flex
  font-size: 14px
  color: rgb(var(--secondaryColor))
  @include bold
  text-transform: uppercase
  :global(.svg-icon)
    margin-right: 10px
    svg
      path
        fill: rgb(var(--secondaryColor))

.rowHorizontal
  background: var(--home_tournamentItemBg)
  border-radius: 4px
  padding: var(--home_tournamentItemPadding)
  min-height: calc(204px + 48px + var(--home_tournamentItemMargin))
  margin-bottom: var(--home_tournamentItemMargin)
  position: relative
  .header
    @include flex
    height: 48px
    padding: 0 70px 0 3px
    > a
      color: rgb(var(--primaryColor))
      white-space: nowrap
      &:hover
        color: rgb(var(--secondaryColor))

    h2:nth-child(2)
      text-transform: uppercase
      margin: 0 auto
      padding: 0 10px
      @include ellipsis
      color: rgb(var(--primaryColor))
