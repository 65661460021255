@import "mixins"
.faqContainer
    background: var(--home_infoBg)
    padding: 14px
    border-top: 1px solid var(--home_infoBorder)
    border-bottom: 1px solid var(--home_infoBorder)
    box-shadow: inset 0 1px var(--home_infoShadow), 0 1px var(--home_infoShadow)
    color: var(--home_infoColor)

    h1
        color: #dfe3e6
        margin-bottom: 10px
        line-height: 24px !important

    .homeAboutTxt
        font-size: 14px
        line-height: 18px
